import i18n from 'i18next'
import React, { useEffect } from 'react'
import { DialogAppointmentService } from '../api/dialogAppointment'
import { Heading } from '../components'
import {
  DialogAppointment,
  PageContainer,
  PageContainerOuter,
  TFormSent,
} from '../components/organisms'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { IAppointment, IDialogData } from '../interfaces/dialogdata'
import { TopicsService } from './../api'
const DialogAppointmentPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const [loaded, setLoaded] = React.useState(false)
  const [dialogFormStatus, setDialogFormStatus] = React.useState<TFormSent>()
  const [token, setToken] = React.useState('')
  const [topics, setTopics] = React.useState()
  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
    setToken(getTokenFromURL())
  }, [])

  const getTokenFromURL = (): string => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get('token') || ''
  }

  const loadTopics = async () => {
    const _topics = await TopicsService.getTopics()
    setTopics(_topics)
  }
  React.useEffect(() => {
    loadTopics()
  }, [])

  const sendForm = (data: IDialogData, appointment: IAppointment) => {
    DialogAppointmentService.sendForm(data, appointment, token).then(
      ({ status }) => {
        setDialogFormStatus(status !== 204 ? 'error' : 'sent')
      }
    )
  }

  return (
    <PageFrame {...params} hideMenu={true} pageName="dialog-appointment">
      <PageContainerOuter>
        <PageContainer>
          {process.env.GATSBY_DIALOG_APPOINTMENT === 'false' ? (
            <>
              <Heading type="h3" style={{ textAlign: 'center' }}>
                Lead capture will be available soon
              </Heading>
            </>
          ) : (
            <>
              {loaded && topics && (
                <DialogAppointment
                  token={token}
                  topics={topics}
                  status={dialogFormStatus}
                  onReset={() => setDialogFormStatus(undefined)}
                  onSubmit={(data: IDialogData, appointment: IAppointment) =>
                    sendForm(data, appointment)
                  }
                />
              )}
            </>
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default DialogAppointmentPage
